import { Classes } from "@blueprintjs/core";
import { CellType } from "@hex/common";
import { rgba } from "polished";
import React, { useCallback } from "react";
import styled from "styled-components";

import { HexButton, HexTag, HexTooltip } from "../../../hex-components";
import { EditableLabel } from "../../cell/shared/EditableLabel";
import {
  ChangelogIcon,
  ChartCellIcon,
  CodeCellIcon,
  ComponentIcon,
  DbtIcon,
  ExploreIcon,
  FilterCellIcon,
  MapCellIcon,
  MarkdownCellIcon,
  PivotCellIcon,
  ProjectIcon,
  SQLCellIcon,
  SingleChevronDownIcon,
  SingleChevronRightIcon,
  SingleValueIcon,
  TableCellIcon,
  TextCellIcon,
  WritebackCellIcon,
} from "../../icons/CustomIcons";

import { ReplaceButton } from "./HighlightedLineMatch.js";

const CountBadge = styled(HexTag)`
  text-align: center;
  min-width: 14px;
  margin-left: 2px;

  &&& {
    font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
    font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
    background-color: ${({ theme }) => rgba(theme.borderColor.MUTED, 0.5)};
    padding: 0 3px;
    color: ${({ theme }) => theme.fontColor.MUTED};
  }
`;

const MatchHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 2px 4px 12px;
  svg {
    color: ${({ theme }) => theme.iconColor};
  }

  ${({ theme }) => `
    &:hover {
      cursor: pointer;
      background-color: ${theme.hoverColor};
      ${ReplaceButton} {
        display: flex;
      }
    }
  `}
`;

const LeftWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
`;

const RightWrapper = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: flex-end;
`;

const CellLabel = styled.div`
  display: flex;
  align-items: center;

  div {
    color: ${({ theme }) => theme.fontColor.DEFAULT};
    font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  }

  /* ensures that pointer shows over ContentEditable label */
  &:hover > * {
    cursor: pointer;
  }

  .${Classes.ICON} {
    margin-right: 6px;
  }
`;

const cellIcon = (
  cellType: CellType | "ProjectMatch",
): React.ReactElement | null => {
  switch (cellType) {
    case CellType.CODE:
      return <CodeCellIcon />;
    case CellType.SQL:
      return <SQLCellIcon />;
    case CellType.WRITEBACK:
      return <WritebackCellIcon />;
    case CellType.PIVOT:
      return <PivotCellIcon />;
    case CellType.FILTER:
      return <FilterCellIcon />;
    case CellType.DBT_METRIC:
      return <DbtIcon />;
    case CellType.COMPONENT_IMPORT:
      return <ComponentIcon />;
    case CellType.TEXT:
      return <TextCellIcon />;
    case CellType.MARKDOWN:
      return <MarkdownCellIcon />;
    case CellType.DISPLAY_TABLE:
      return <TableCellIcon />;
    case CellType.VEGA_CHART:
    case CellType.CHART:
      return <ChartCellIcon />;
    case CellType.EXPLORE:
      return <ExploreIcon />;
    case CellType.METRIC:
      return <SingleValueIcon />;
    case CellType.MAP:
      return <MapCellIcon />;
    case "ProjectMatch":
      return <ProjectIcon />;
    default:
      return null;
  }
};

interface MatchHeaderProps {
  type: CellType | "ProjectMatch";
  label: string;
  matchCount: number;
  expanded: boolean;
  collapseSection: (expanded: boolean) => void;
  goToCell: () => void;
  replaceAllForCell?: () => void;
  showReplaceAction: boolean;
}

export const MatchHeader: React.ComponentType<MatchHeaderProps> = React.memo(
  function MatchHeader({
    collapseSection,
    expanded,
    goToCell,
    label,
    matchCount,
    replaceAllForCell,
    showReplaceAction,
    type: cellType,
  }) {
    const collapseCell = useCallback(
      (evt) => {
        // make sure that the event is not bubbled up; we do not want to
        // scroll or focus on the cell, since the dropdown menu is a child
        // of the header selection
        evt.stopPropagation();
        collapseSection(!expanded);
      },
      [collapseSection, expanded],
    );
    return (
      <MatchHeaderWrapper onClick={goToCell}>
        <LeftWrapper>
          <CellLabel>
            {cellIcon(cellType)}{" "}
            <EditableLabel editable={false} label={label} />
            <CountBadge minimal={true} small={true}>
              {matchCount}
            </CountBadge>
          </CellLabel>
        </LeftWrapper>
        <RightWrapper>
          {replaceAllForCell && showReplaceAction && (
            <HexTooltip content="Replace all in cell" placement="bottom">
              <ReplaceButton
                icon={<ChangelogIcon />}
                small={true}
                onClick={replaceAllForCell}
              />
            </HexTooltip>
          )}
          <HexButton
            css={`
              &&&&&&&& {
                &:hover {
                  background-color: transparent;
                }
              }
            `}
            icon={
              expanded ? <SingleChevronDownIcon /> : <SingleChevronRightIcon />
            }
            minimal={true}
            small={true}
            subtle={true}
            onClick={collapseCell}
          />
        </RightWrapper>
      </MatchHeaderWrapper>
    );
  },
);
